import './bootstrap'
import './sprite'
import * as modules from './modules'

import.meta.glob([
    '../images/**'
])

// Some functionalities should be loaded immediately instead of on init.
modules.recaptcha()
modules.tinySliders()

document.addEventListener('DOMContentLoaded', () => {
    // Order matters!
    modules.images()
    modules.blogDetailHeaders()
    modules.navigation()
    modules.accordion()
    modules.video()
    modules.tabs()
    modules.dropdown()
    modules.scrollTo()
    modules.form()
    modules.search()
    modules.storeLocator()
    modules.notificationBar()
    modules.compare.init()
    modules.languageSwitch()
    modules.listingFilters()
    modules.blogFilters()
    modules.product()
    modules.downloadSelect()
    modules.newsletterPopup()
    modules.nitro()
    modules.nitroForms()
    modules.visoSignup()
    modules.enableVisoButtons()
    modules.animatedVideo()
    modules.tagManagerEvents()
})
